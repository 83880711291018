import { Suspense, forwardRef, useCallback, useEffect, useMemo } from 'react';
import type { Ref } from 'react';
import { observer } from 'mobx-react-lite';
import type { InsuredAmountConfigurationValues } from '@pulse-smart-components-kit/insured-amount-configuration';
import { WidgetContainer } from '@pulse-web-ui/containers';
import { SegmentGroup } from '@pulse-web-ui/segment-group';

import { useStores } from '@shared/hooks';
import { FallbackSkeleton } from '@shared/components';
import { SmartInsuranceAmountConfiguration } from '@entities/import-smart-components/smart-insurance-amount-configuration';
import type { SmartComponentAdapterProps } from 'smart-components';
import {
  StyledWrapper,
  StyledPeriodWrapper,
} from './insurance-period-and-limits-adapter.style';
import { prepareConfigurationOptions, prepareLimits } from './utils';
import { useTranslation } from 'react-i18next';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';
import { useHandleGetSubobjects } from './queries';
import type { SelectedDuration } from '@shared/types';
import { preparePrices } from './utils/prepare-prices';
import { useWindowSize } from '@shared/hooks';

export const InsurancePeriodAndLimitsAdapter = observer(
  forwardRef<
    HTMLDivElement,
    SmartComponentAdapterProps<InsuredAmountConfigurationValues>
  >(({ isSubmitting, onChange, value }, forwardRef: Ref<HTMLDivElement>) => {
    const {
      MainStore: {
        authStore: { disableForm },
        productStore: { price, period, setPeriod },
      },
    } = useStores();
    const { t } = useTranslation();

    const { data, isLoading } = useHandleGetSubobjects();

    useEffect(() => {
      if (!data) return;
      onChange({ limits: prepareLimits(data), isValid: true });
    }, [data]);

    const [windowWidth] = useWindowSize();

    const pricesOptions = useMemo(() => {
      return preparePrices(windowWidth, price.prices);
    }, [windowWidth, price.prices]);

    const handleLimitsChange = (value: InsuredAmountConfigurationValues) => {
      onChange(value);
    };

    const handlePeriodChange = useCallback((value: SelectedDuration) => {
      setPeriod(value);
    }, []);

    if (isLoading || !data) {
      return (
        <WidgetContainer>
          <FallbackSkeleton height={820} />
        </WidgetContainer>
      );
    }

    return (
      <WidgetContainer>
        <Suspense fallback={<FallbackSkeleton height={800} />}>
          <StyledWrapper>
            <StyledPeriodWrapper>
              <HeaderWithSubText
                title={t('INSURANCE_PERIOD_AND_LIMITS:label.periodName')}
              />
              {pricesOptions ? (
                <SegmentGroup
                  name="period"
                  options={pricesOptions}
                  onChange={handlePeriodChange}
                  defaultValue={period ?? price.defaultContractDuration}
                />
              ) : (
                <FallbackSkeleton height={40} />
              )}
            </StyledPeriodWrapper>
            <SmartInsuranceAmountConfiguration
              disabled={disableForm}
              isSubmitting={isSubmitting}
              onChange={handleLimitsChange}
              ref={forwardRef}
              options={{
                options: prepareConfigurationOptions(data, value),
                subTitle: t('INSURANCE_PERIOD_AND_LIMITS:label.subTitle'),
                title: t('INSURANCE_PERIOD_AND_LIMITS:label.title'),
              }}
            />
          </StyledWrapper>
        </Suspense>
      </WidgetContainer>
    );
  })
);

InsurancePeriodAndLimitsAdapter.displayName = 'InsurancePeriodAndLimitsAdapter';
