import { styled, typography } from '@pulse-web-ui/theme';

export const StyledWrapper = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
`;

export const StyledPeriodWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const PeriodBadge = styled.span`
  display: inline-block;
  padding: 0px 4px;
  border-radius: 16px;
  background: #1aa800;
  ${typography.regular12};
  line-height: 18px;
  color: #ffffff;
`;
