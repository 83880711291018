/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, memo, useCallback, useEffect, useMemo } from 'react';
import type { ChangeEvent, ChangeEventHandler, FC, Ref } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@pulse-web-ui/checkbox';
import { Datepicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';
import { Switch } from '@pulse-web-ui/switch';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';

import { InputPhone } from './components';
import { getFormInsuranceContactsSchema } from '@shared/scheme';
import {
  Form,
  Row,
  RowLast,
  RowSection,
  RowSectionBasic,
  RowSwitcher,
  FormWrapper,
} from './who-issues-policy.style';
import { i18nDefaultValues } from './i18n';
import type {
  WhoIssuesPolicyValues,
  WhoIssuesPolicyOptions,
  WhoIssuesPolicyDisableState,
} from './who-issues-policy.type';
import { phoneRegExp } from '@shared/regexps';
import { useUpdateLocalForm } from '@shared/hooks';
import type { SmartComponentProps } from 'smart-components';
import { phoneValueCasting } from '@shared/utils';

export enum WhoIssuesPolicyAnalyticEvent {
  ON_PHONE_BLUR,
  ON_EMAIL_BLUR,
  ON_USER_BLUR,
}

const WhoIssuesPolicy: FC<
  SmartComponentProps<
    WhoIssuesPolicyValues,
    WhoIssuesPolicyOptions,
    WhoIssuesPolicyAnalyticEvent
  >
> = memo(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        disabled,
        fieldState: extFieldState,
        options,
        onAnalyticEventSend,
      },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();
      const maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() - 18);
      maxDate.setDate(maxDate.getDate() - 1);

      const formInsuranceContactsSchema = useMemo(
        () => getFormInsuranceContactsSchema(options.agentLogin),
        [options.agentLogin]
      );

      const {
        trigger,
        clearErrors,
        control,
        watch,
        getValues,
        setValue,
        formState: { isValid },
        reset,
        setError,
      } = useForm<WhoIssuesPolicyValues>({
        values: value,
        mode: 'onSubmit',
        resetOptions: {
          keepDirtyValues: true,
          keepErrors: true,
        },
        resolver: yupResolver(formInsuranceContactsSchema),
        context: {},
      });

      useUpdateLocalForm<WhoIssuesPolicyValues>(
        getValues,
        value,
        setValue,
        formInsuranceContactsSchema
      );

      const handleBlurPhone = () => {
        const { phone } = getValues();
        if (phone && phoneRegExp.test(phone)) {
          onAnalyticEventSend &&
            onAnalyticEventSend(WhoIssuesPolicyAnalyticEvent.ON_PHONE_BLUR);
        }
      };

      const hanldeBlurEmail = () => {
        onAnalyticEventSend &&
          onAnalyticEventSend(WhoIssuesPolicyAnalyticEvent.ON_EMAIL_BLUR);
        // Кейс авторизованного пользователя без емейла
        getValues('hasAuthorized') && trigger();
      };

      const handleBlurUser = () => {
        const { name, surname, birthDate } = getValues();
        if (name && surname && birthDate) {
          onAnalyticEventSend &&
            onAnalyticEventSend(WhoIssuesPolicyAnalyticEvent.ON_USER_BLUR);
        }
      };

      const handleChangeBirthdate =
        (onChane: (value: Date) => void) => (value: Date) => {
          onChane(value);
          clearErrors('birthDate');

          getValues('hasAuthorized') &&
            options.onChangeAuthorizedBirthDate &&
            options.onChangeAuthorizedBirthDate(value);
          handleBlurUser();
        };

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      useEffect(() => {
        if (extFieldState?.error?.phone) {
          setError('phone', {
            message: extFieldState?.error?.phone?.message || '',
          });
        }
        if (extFieldState?.error?.name) {
          setError('name', {
            message: extFieldState?.error?.name?.message || '',
          });
        }
        if (extFieldState?.error?.surname) {
          setError('surname', {
            message: extFieldState?.error?.surname?.message || '',
          });
        }
        if (extFieldState?.error?.secondName) {
          setError('secondName', {
            message: extFieldState?.error?.secondName?.message || '',
          });
        }
        if (extFieldState?.error?.birthDate) {
          setError('birthDate', {
            message: extFieldState?.error?.birthDate?.message || '',
          });
        }
        if (extFieldState?.error?.email) {
          setError('email', {
            message: extFieldState?.error?.email?.message || '',
          });
        }
        if (extFieldState.client) {
          reset({ client: extFieldState.client });
        }
      }, [extFieldState]);

      useEffect(() => {
        if ((value?.hasAuthorized && !getValues().hasAuthorized) || !value) {
          reset(value);
          trigger();
        }
      }, [value]);

      const checkIfAgentPhone = useCallback(() => {
        const phoneVal = phoneValueCasting(getValues('phone') || '');

        if (!!options.agentLogin && phoneVal === options.agentLogin) {
          setError('phone', { message: 'COMMON:errors.numberMatchesAgent' });
        }
      }, [getValues('phone'), options.agentLogin]);

      useEffect(() => {
        checkIfAgentPhone();
      }, [checkIfAgentPhone]);

      const handleChangeForMe = useCallback(
        (onChange: ChangeEventHandler) =>
          (value: ChangeEvent<HTMLInputElement>) => {
            onChange(value);
            clearErrors('forMe');
          },
        []
      );

      useEffect(() => {
        const subscription = watch((values) => {
          onChange(values as WhoIssuesPolicyValues);
        });

        return () => subscription.unsubscribe();
      }, [watch]);

      useEffect(() => {
        // Передача isValid в форму форм билдера
        setValue('isValid', isValid);
      }, [isValid]);

      useEffect(() => {
        setValue('isValid', true);
        // Получение профайла
        setValue('isValid', value?.isValid);
      }, [value?.isValid]);

      useEffect(() => {
        // Для неавторизованных пользователей задаём начальное значение.
        !getValues('hasAuthorized') && setValue('isValid', true);
      }, []);

      const handleChange = useCallback(
        <TName extends keyof WhoIssuesPolicyValues, TValue>(
          onChange: ControllerRenderProps<
            WhoIssuesPolicyValues,
            TName
          >['onChange'],
          name: TName
        ) =>
          (value: TValue) => {
            onChange(value);
            clearErrors(name);
          },
        [clearErrors]
      );

      const getDisableState = useCallback(
        (name: keyof WhoIssuesPolicyDisableState) => {
          if (options?.disableProfileState?.[name] === false) return false;
          if (getValues('hasAuthorized') || disabled) return true;

          switch (name) {
            case 'phone':
              return (
                Boolean(options?.deviceType) &&
                Boolean(options?.phoneFromMobileApp)
              );
          }
        },
        [disabled, options?.deviceType, options?.phoneFromMobileApp]
      );

      return (
        <Form ref={forwardRef} data-test="Who-issued-policy-block">
          <HeaderWithSubText
            title={t('SMART:WhoIssuesPolicy.title', {
              defaultValue: i18nDefaultValues.WhoIssuesPolicy.title,
            })}
            subTitle={t('SMART:WhoIssuesPolicy.subTitle', {
              defaultValue: i18nDefaultValues.WhoIssuesPolicy.subTitle,
            })}
          />
          {getValues('showSwitcher') && !getValues('hasAuthorized') ? (
            <RowSwitcher>
              <RowSectionBasic data-test="Client-switcher">
                <Controller
                  render={({ field: { onChange, value, name } }) => (
                    <Switch
                      onChange={handleChange<typeof name, boolean>(
                        onChange,
                        name
                      )}
                      value={value}
                      disabled={getValues('hasAuthorized') || disabled}
                      label={t('SMART:WhoIssuesPolicy.labels.client', {
                        defaultValue:
                          i18nDefaultValues.WhoIssuesPolicy.labels.client,
                      })}
                      labelDirection="reverse"
                    />
                  )}
                  control={control}
                  name="client"
                />
              </RowSectionBasic>
            </RowSwitcher>
          ) : null}
          {getValues('client') && !getValues('hasAuthorized') ? (
            <RowLast>
              <RowSection>
                <Controller
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <HelperText
                      noMargin
                      status={error ? 'error' : 'default'}
                      message={error?.message && t(error?.message)}
                    >
                      <div data-test="Phone">
                        <InputPhone
                          disabled={getDisableState('phone')}
                          onBlur={handleBlurPhone}
                          onChange={handleChange<typeof name, string>(
                            onChange,
                            name
                          )}
                          value={value}
                          error={!!error}
                        />
                      </div>
                    </HelperText>
                  )}
                  control={control}
                  name="phone"
                />
              </RowSection>
              <RowSection />
            </RowLast>
          ) : (
            <>
              <FormWrapper>
                <Row>
                  <RowSection>
                    <Controller
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <HelperText
                          noMargin
                          status={error ? 'error' : 'default'}
                          message={error?.message && t(error?.message)}
                        >
                          <Input
                            noMargin
                            onChange={handleChange<typeof name, string>(
                              onChange,
                              name
                            )}
                            onBlur={handleBlurUser}
                            value={value}
                            disabled={getDisableState('surname')}
                            error={!!error}
                            label={t('SMART:WhoIssuesPolicy.labels.surname', {
                              defaultValue:
                                i18nDefaultValues.WhoIssuesPolicy.labels
                                  .surname,
                            })}
                            data-test="Surname"
                          />
                        </HelperText>
                      )}
                      control={control}
                      name="surname"
                    />
                  </RowSection>
                  <RowSection>
                    <Controller
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <HelperText
                          noMargin
                          status={error ? 'error' : 'default'}
                          message={error?.message && t(error?.message)}
                        >
                          <Input
                            noMargin
                            onChange={handleChange<typeof name, string>(
                              onChange,
                              name
                            )}
                            onBlur={handleBlurUser}
                            disabled={getDisableState('name')}
                            value={value}
                            error={!!error}
                            label={t('SMART:WhoIssuesPolicy.labels.name', {
                              defaultValue:
                                i18nDefaultValues.WhoIssuesPolicy.labels.name,
                            })}
                            data-test="Name"
                          />
                        </HelperText>
                      )}
                      control={control}
                      name="name"
                    />
                  </RowSection>
                </Row>
                <Row>
                  <RowSection>
                    <Controller
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <HelperText
                          noMargin
                          status={error ? 'error' : 'default'}
                          message={
                            error?.message
                              ? t(error?.message)
                              : t('SMART:WhoIssuesPolicy.hints.middleName', {
                                  defaultValue:
                                    i18nDefaultValues.WhoIssuesPolicy.hints
                                      .middleName,
                                })
                          }
                        >
                          <Input
                            noMargin
                            onChange={handleChange<typeof name, string>(
                              onChange,
                              name
                            )}
                            onBlur={handleBlurUser}
                            value={value || ''}
                            disabled={getDisableState('secondName')}
                            error={!!error}
                            label={t(
                              'SMART:WhoIssuesPolicy.labels.middleName',
                              {
                                defaultValue:
                                  i18nDefaultValues.WhoIssuesPolicy.labels
                                    .middleName,
                              }
                            )}
                            data-test="Middlename"
                          />
                        </HelperText>
                      )}
                      control={control}
                      name="secondName"
                    />
                  </RowSection>
                  <RowSection>
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <HelperText
                          noMargin
                          status={error ? 'error' : 'default'}
                          message={error?.message && t(error?.message)}
                        >
                          <Datepicker
                            onChange={handleChangeBirthdate(onChange)}
                            showYearDropdown
                            showMonthDropdown
                            disabledKeyboardNavigation
                            showInput
                            disabled={getDisableState('birthDate')}
                            selected={value}
                            maxDate={maxDate}
                            label={t('SMART:WhoIssuesPolicy.labels.birthDate', {
                              defaultValue:
                                i18nDefaultValues.WhoIssuesPolicy.labels
                                  .birthDate,
                            })}
                            error={!!error}
                            data-test="Birthdate"
                          />
                        </HelperText>
                      )}
                      control={control}
                      name="birthDate"
                    />
                  </RowSection>
                </Row>
                <Row>
                  <RowSection>
                    <Controller
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <HelperText
                          noMargin
                          status={error ? 'error' : 'default'}
                          message={
                            error?.message
                              ? t(error?.message)
                              : t('SMART:WhoIssuesPolicy.hints.phone', {
                                  defaultValue:
                                    i18nDefaultValues.WhoIssuesPolicy.hints
                                      .phone,
                                })
                          }
                        >
                          <div data-test="Phone">
                            <InputPhone
                              onChange={handleChange<typeof name, string>(
                                onChange,
                                name
                              )}
                              onBlur={handleBlurPhone}
                              disabled={getDisableState('phone')}
                              value={value}
                              error={!!error}
                            />
                          </div>
                        </HelperText>
                      )}
                      control={control}
                      name="phone"
                    />
                  </RowSection>
                  <RowSection>
                    <Controller
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <HelperText
                          status={error ? 'error' : 'default'}
                          message={
                            error?.message
                              ? t(error?.message)
                              : t('SMART:WhoIssuesPolicy.hints.sendByMail', {
                                  defaultValue:
                                    i18nDefaultValues.WhoIssuesPolicy.hints
                                      .sendByMail,
                                })
                          }
                        >
                          <Input
                            onChange={handleChange<typeof name, string>(
                              onChange,
                              name
                            )}
                            onBlur={hanldeBlurEmail}
                            value={value}
                            disabled={disabled}
                            error={!!error}
                            label={t('SMART:WhoIssuesPolicy.labels.email', {
                              defaultValue:
                                i18nDefaultValues.WhoIssuesPolicy.labels.email,
                            })}
                            data-test="Email"
                          />
                        </HelperText>
                      )}
                      control={control}
                      name="email"
                    />
                  </RowSection>
                </Row>
              </FormWrapper>
              {getValues('hasAuthorized') ? (
                <Row>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <div data-test="It's me">
                        <Checkbox
                          onChange={handleChangeForMe(onChange)}
                          disabled={disabled}
                          checked={value}
                          label={t('SMART:WhoIssuesPolicy.labels.forMe', {
                            defaultValue:
                              i18nDefaultValues.WhoIssuesPolicy.labels.forMe,
                          })}
                        />
                      </div>
                    )}
                    control={control}
                    name="forMe"
                  />
                </Row>
              ) : null}
            </>
          )}
        </Form>
      );
    }
  )
);

export default WhoIssuesPolicy;
